@import url('https://fonts.googleapis.com/css2?family=Caudex&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;600;700&display=swap');

/* Base Styles */
html, body, #root, .App {
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  position: relative; /* Added for animated background gradient */
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.left, .right {
  box-sizing: border-box;
  min-width: 0;
  flex: 1;
}

.left {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  position: relative;
  background-image: radial-gradient(#00000010 1px, transparent 1px);
  background-size: 20px 20px;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e6e6e6;
  position: relative;
}

/* Enhanced Header Typography */
.right h1 {
  font-family: 'Caudex', serif;
  font-size: 55px;
  margin: 0;
  position: relative;
  line-height: 1.2;
}

/* First h1 (Hi, My Name Is) */
.right h1:first-of-type {
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  color: #666;
  margin-bottom: 8px;
  animation: fadeSlideUp 0.6s ease forwards;
  animation-delay: 0.2s;
  opacity: 0;
}

/* Second h1 (Benjamin Pittenger) with gradient and glitch effects */
.right h1:nth-of-type(2) {
  font-weight: 700;
  background: linear-gradient(45deg, #2196F3, #00BCD4, #3F51B5, #2196F3);
  background-size: 300% 300%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 16px;
  animation: gradient-shift 8s ease infinite, fadeSlideUp 0.6s ease forwards;
  animation-delay: 0s, 0.4s;
  opacity: 0;
  position: relative; /* Added for glitch effect */
}

.right h1:nth-of-type(2)::before,
.right h1:nth-of-type(2)::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  -webkit-background-clip: text;
  background-clip: text;
  opacity: 0;
}

.right h1:nth-of-type(2):hover::before {
  animation: glitch 0.3s cubic-bezier(.25, .46, .45, .94) both infinite;
  color: #00ff00;
  opacity: 0.5;
  left: 2px;
}

.right h1:nth-of-type(2):hover::after {
  animation: glitch 0.3s cubic-bezier(.25, .46, .45, .94) reverse both infinite;
  color: #ff0000;
  opacity: 0.5;
  left: -2px;
}

/* Subheader text */
.right p {
  font-family: 'Nunito', sans-serif;
  font-size: 24px;
  position: relative;
  padding-bottom: 24px;
  margin-bottom: 32px;
  animation: fadeSlideUp 0.6s ease forwards;
  animation-delay: 0.6s;
  opacity: 0;
}

/* Animated underline */
.right p::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, #2196F3, #00BCD4);
  border-radius: 2px;
  transition: width 0.3s ease;
}

.right p:hover::after {
  width: 100%;
}

/* Enhanced Image Styles */
.image-box {
  width: 450px;
  height: 450px;
  position: relative;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform;
  transform-style: preserve-3d; /* Added for 3D effect */
  perspective: 1000px; /* Added for 3D effect */
}

.image-box::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-box:hover {
  transform: translateY(-5px);
}

.image-box:hover::after {
  opacity: 1;
}

.image-box img {
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: transform 0.3s ease;
  transform: translateZ(20px); /* Added for 3D effect */
}

/* Enhanced Button Styles */
.buttons {
  animation: fadeSlideUp 0.6s ease forwards;
  animation-delay: 0.8s;
  opacity: 0;
}

.buttons a {
  text-decoration: none;
  color: inherit;
}

.buttons button {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 15px 0;
  width: 431px;
  height: 50px;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 2px solid transparent;
  background: 
    linear-gradient(to right, #fff, #fff),
    linear-gradient(45deg, #2196F3, #00BCD4);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  border-radius: 12px;
  transform-style: preserve-3d; /* Added for magnetic effect */
  perspective: 1000px; /* Added for magnetic effect */
}

.buttons button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120%;
  height: 120%;
  background: linear-gradient(45deg, #2196F3, #00BCD4);
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.6s ease;
  z-index: -1;
  border-radius: 8px;
}

.buttons button:hover {
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.buttons button:hover::before {
  transform: translate(-50%, -50%) scale(1);
}

/* Enhanced Toggle Switch Styles */
.toggle-container {
  position: absolute;
  top: 20px;
  right: 20px;
}

.toggle-container-mobile {
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(8px);
  background: rgba(204, 204, 204, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: .4s;
  overflow: hidden; /* Added for enhanced toggle switch */
}

.toggle-slider::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent 40%,
    rgba(255, 255, 255, 0.2) 50%,
    transparent 60%
  );
  transform: translate(-100%, -100%);
  transition: transform 0.6s ease;
}

.toggle-switch:hover .toggle-slider::after {
  transform: translate(0, 0);
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

input:checked + .toggle-slider {
  background-color: #2196F3;
}

input:checked + .toggle-slider:before {
  transform: translateX(26px);
}

.toggle-slider.round {
  border-radius: 34px;
}

.toggle-slider.round:before {
  border-radius: 50%;
}

/* Enhanced Dark Mode Styles */
.dark-mode {
  background-color: #333;
  color: #fff;
}

.dark-mode .left {
  background-color: #444;
  background-image: radial-gradient(#ffffff10 1px, transparent 1px);
}

.dark-mode .right {
  background-color: #555;
}

.dark-mode .buttons button {
  background: 
    linear-gradient(to right, #444, #444),
    linear-gradient(45deg, #64B5F6, #4DD0E1);
  color: #fff;
}

.dark-mode .toggle-slider {
  background-color: #777;
}

.dark-mode input:checked + .toggle-slider {
  background-color: #999;
}

.dark-mode .right h1:first-of-type {
  color: #aaa;
}

.dark-mode .right h1:nth-of-type(2) {
  background: linear-gradient(45deg, #64B5F6, #4DD0E1, #7986CB, #64B5F6);
  -webkit-background-clip: text;
  background-clip: text;
}

.dark-mode .right p::after {
  background: linear-gradient(90deg, #64B5F6, #4DD0E1);
}

.dark-mode .buttons button:hover {
  box-shadow: 0 8px 16px 0 rgba(255, 255, 255, 0.2);
}

/* Animation Keyframes */
@keyframes fadeSlideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes gradient-shift {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Text glitch effect for name on hover */
@keyframes glitch {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-2px, 2px);
  }
  40% {
    transform: translate(-2px, -2px);
  }
  60% {
    transform: translate(2px, 2px);
  }
  80% {
    transform: translate(2px, -2px);
  }
  100% {
    transform: translate(0);
  }
}

/* Animated background gradient */
.App::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, 
    rgba(33, 150, 243, 0.1),
    rgba(0, 188, 212, 0.1),
    rgba(63, 81, 181, 0.1)
  );
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
  z-index: 0;
  pointer-events: none;
}

@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Magnetic button effect */
.buttons button {
  /* Existing styles... */
  /* Added transform-style and perspective in the existing button styles */
}

/* Loading animation */
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.loading {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

/* Matrix Canvas */
#matrixCanvas {
  position: absolute;
  z-index: 10;
  background-color: transparent;
  pointer-events: none;
}

/* Media Queries */
@media screen and (max-width: 1024px) {
  .image-box {
    width: 350px;
    height: 350px;
  }
  
  .right h1:first-of-type {
    font-size: 20px;
  }
  
  .right h1:nth-of-type(2) {
    font-size: 45px;
  }
  
  .right p {
    font-size: 20px;
    padding-bottom: 20px;
    margin-bottom: 28px;
  }
  
  .buttons button {
    width: 350px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  }
  
  .left, .right {
    width: 100%;
    flex: none;
  }
  
  .toggle-container {
    display: none;
  }
  
  .toggle-container-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .image-box {
    width: 100%;
    height: auto;
  }
  
  .responsive-image {
    width: 100%;
    height: auto;
  }
  
  .right-content {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
  }
  
  .right h1:first-of-type {
    font-size: 18px;
  }
  
  .right h1:nth-of-type(2) {
    font-size: 35px;
  }
  
  .right p {
    font-size: 18px;
    padding-bottom: 16px;
    margin-bottom: 24px;
  }
  
  .buttons a {
    display: flex;
    justify-content: center;
  }
  
  .buttons button {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 480px) {
  .right h1:first-of-type {
    font-size: 16px;
  }
  
  .right h1:nth-of-type(2) {
    font-size: 30px;
  }
  
  .right p {
    font-size: 16px;
    padding-bottom: 14px;
    margin-bottom: 20px;
  }
}
